/*!
/*!
 *  VIXION Font 0.1.0 by @lazcanotegui 
 */
/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'VixionFont';
  src:  url('../fonts/vixion-font.0.1.11.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.vi {
  display: inline-block;
  font: normal normal normal 14px/1 VixionFont;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* makes the font 33% larger relative to the icon container */
.vi-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.vi-2x {
  font-size: 2em;
}
.vi-3x {
  font-size: 3em;
}
.vi-4x {
  font-size: 4em;
}
.vi-5x {
  font-size: 5em;
}
.vi-fw {
  width: 1.28571429em;
  text-align: center;
}
.vi-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.vi-ul > li {
  position: relative;
}
.vi-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.vi-li.vi-lg {
  left: -1.85714286em;
}
.vi-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.vi-pull-left {
  float: left;
}
.vi-pull-right {
  float: right;
}
.vi.vi-pull-left {
  margin-right: .3em;
}
.vi.vi-pull-right {
  margin-left: .3em;
}
/* Deprecated as of 4.4.0 */
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.vi.pull-left {
  margin-right: .3em;
}
.vi.pull-right {
  margin-left: .3em;
}
.vi-spin {
  -webkit-animation: vi-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.vi-pulse {
  -webkit-animation: vi-spin 1s infinite steps(8);
  animation: vi-spin 1s infinite steps(8);
}
@-webkit-keyframes vi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes vi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.vi-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.vi-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.vi-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.vi-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.vi-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .vi-rotate-90,
:root .vi-rotate-180,
:root .vi-rotate-270,
:root .vi-flip-horizontal,
:root .vi-flip-vertical {
  filter: none;
}
.vi-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.vi-stack-1x,
.vi-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.vi-stack-1x {
  line-height: inherit;
}
.vi-stack-2x {
  font-size: 2em;
}
.vi-inverse {
  color: #ffffff;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}


/* VIXION Font uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.vi-one-revolution:before {
  content: "\00B8";
}

.vi-closed-loop:before {
  content: "\00B9";
}

.vi-interchange:before {
  content: "\00BB";
}

.vi-sensitive:before {
  content: "\00BA";
}


.vi-standby:before {
  content: "\00BC";
}

.vi-update-edge:before {
  content: "\0010";
}


.vi-no-responsive:before {
  content: "\0011";
}

.vi-measurement:before {
  content: "\0012";
}

.vi-unknown:before {
  content: "\0013";
}

.vi-clear-list:before {
  content: "\0014";
}
.vi-gear:before {
  content: "\0015";
}

.vi-manager:before {
  content: "\0016";
}

.vi-documentation:before {
  content: "\0017";
}

.vi-bearing:before {
  content: "\0018";
}

.vi-maintenance:before {
  content: "\0019";
}

.vi-mtorres-laying-head:before {
  content: "\001A";
}

.vi-insights:before {
  content: "\001B";
}

.vi-power:before {
  content: "\001C";
}

.vi-sleep:before {
  content: "\001D";
}


.vi-additive-manufacturing:before {
  content: "\001E";
}

.vi-cameras:before {
  content: "\001F";
}

.vi-users:before {
  content: "\00B7";
}


.vi-drill:before {
  content: "\0021";
}

.vi-cloud-disconnected:before {
  content: "\0022";
}

.vi-cloud-connected:before {
  content: "\0023";
}
.vi-temperature:before {
  content: "\0024";
}

.vi-developer:before {
  content: "\0025";
}

.vi-vixion-edge:before {
  content: "\0026";
}

.vi-fleet:before {
  content: "\0027";
}

.vi-machine-catalog:before {
  content: "\0028";
}


.vi-machine-add:before {
  content: "\0029";
}

.vi-datalogger:before {
  content: "\002A";
}

.vi-factory:before {
  content: "\002B";
}

.vi-duplicate-machine:before {
  content: "\002C";
}

.vi-machine:before {
  content: "\002D";
}

.vi-hydraulic:before {
  content: "\002E";
}

.vi-ballscrew:before {
  content: "\002F";
}

.vi-drive:before {
  content: "\0030";
}
.vi-electrospindle:before {
  content: "\0031";
}
.vi-runprogram:before {
  content: "\0032";
}
.vi-pulsebutton:before {
  content: "\0033";
}
.vi-gears:before {
  content: "\0034";
}
.vi-executedprograms:before {
  content: "\0035";
}
.vi-presure:before {
  content: "\0036";
}
.vi-greasing:before {
  content: "\0037";
}
.vi-cilinder:before {
  content: "\0038";
}
.vi-hydraulictemperature:before {
  content: "\0039";
}
.vi-hydraulicpower:before {
  content: "\003a";
}
.vi-rackpinion:before {
  content: "\003b";
}
.vi-feed:before {
  content: "\003c";
}
.vi-millingtool:before {
  content: "\003d";
}
.vi-part:before {
  content: "\003e";
}

.vi-filter:before {
  content: "\003f";
}

.vi-cooler:before {
  content: "\0040";
}


.vi-zayerhead:before {
  content: "\0041";
}
.vi-zayertwist:before {
  content: "\0042";
}

.vi-zayerelectrospindle:before {
  content: "\0043";
}

.vi-zayerdirect:before {
  content: "\0044";
}

.vi-zayer45:before {
  content: "\0045";
}

.vi-zayer30:before {
  content: "\0046";
}

.vi-zayerLshort:before {
  content: "\0047";
}

.vi-zayerLlarge:before {
  content: "\0048";
}

.vi-zayerorthogonal:before {
  content: "\0049";
}

.vi-plc:before {
  content: "\004a";
}


.vi-plcerror:before {
  content: "\004b";
}

.vi-cnc:before {
  content: "\004c";
}

.vi-fullrun:before {
  content: "\004d";
}

.vi-mdi:before {
  content: "\004e";
}

.vi-handwheel:before {
  content: "\004f";
}

.vi-singleblock:before {
  content: "\0050";
}

.vi-manual:before {
  content: "\0051";
}

.vi-run:before {
  content: "\0052";
}

.vi-emergency:before {
  content: "\0053";
}

.vi-pause:before {
  content: "\0054";
}

.vi-warning:before {
  content: "\0055";
}
.vi-idle:before {
  content: "\0056";
}

.vi-on:before {
  content: "\0057";
}

.vi-off:before {
  content: "\0058";
}

.vi-speed:before {
  content: "\0059";
}

.vi-wire:before {
  content: "\005a";
}

.vi-database:before {
  content: "\005b";
}

.vi-eco:before {
  content: "\005c";
}

.vi-edmwire:before {
  content: "\005d";
}

.vi-edmpenetration:before {
  content: "\005e";
}

.vi-edm:before {
  content: "\005f";
}

.vi-ampere:before {
  content: "\0060";
}

.vi-voltage:before {
  content: "\0061";
}

.vi-current:before {
  content: "\0062";
}

.vi-polarization:before {
  content: "\0063";
}


.vi-feedrateoverride:before {
  content: "\0064";
}

.vi-spindleoverride:before {
  content: "\0065";
}

.vi-rapidoverride:before {
  content: "\0066";
}

.vi-override:before {
  content: "\0067";
}

.vi-switchon:before {
  content: "\0068";
}

.vi-disconnector:before {
  content: "\0069";
}

.vi-ibarmiaspindle:before {
  content: "\006a";
}


.vi-ibarmiamachine:before {
  content: "\006b";
}


.vi-pallet:before {
  content: "\006c";
}

.vi-ibarmiaspeed:before {
  content: "\006d";
}

.vi-ibarmiaload:before {
  content: "\006e";
}

.vi-correaudx:before {
  content: "\006f";
}


.vi-correauad:before {
  content: "\0070";
}

.vi-turning:before {
  content: "\0071";
}

.vi-flexipress:before {
  content: "\0072";
}

.vi-rollforming:before {
  content: "\0073";
}

.vi-coil:before {
  content: "\0074";
}

.vi-loop:before {
  content: "\0075";
}

.vi-toolholder:before {
  content: "\0076";
}


.vi-angles:before {
  content: "\0079";
}

.vi-return:before {
  content: "\007a";
}

.vi-notification:before {
  content: "\007b";
}

.vi-path:before {
  content: "\007c";
}

.vi-points:before {
  content: "\007d";
}

.vi-timeline:before {
  content: "\007e";
}




.vi-bell-filled:before {
  content: "\00A9";
}


.vi-bell-ring-filled:before {
  content: "\00AA";
}


.vi-user-add-filled:before {
  content: "\00AB";
}


.vi-user-filled:before {
  content: "\00AC";
}

.vi-machine-catalog-filled:before {
  content: "\00AD";
}

.vi-machine-add-filled:before {
  content: "\00AE";
}

.vi-machine-duplicate-filled:before {
  content: "\00AF";
}

.vi-machine-filled:before {
  content: "\00B0";
}

.vi-fleet-filled:before {
  content: "\00B1";
}

.vi-filter-filled:before {
  content: "\00B2";
}

.vi-rackpinion-filled:before {
  content: "\00B3";
}


.vi-greasing-filled:before {
  content: "\00B4";
}

.vi-angular-filled:before {
  content: "\00B5";
}

.vi-preasure-filled:before {
  content: "\00B6";
}


.vi-vixion-text:before {
  content: "\00BD";
}

.vi-vixion:before {
  content: "\00BE";
}

.vi-vixion-filled:before {
  content: "\00BF";
}


.vi-spyro:before {
  content: "\00C0";
}

.vi-ona:before {
  content: "\00c1";
}

.vi-zayer:before {
  content: "\00c2";
}


.vi-ibarmia:before {
  content: "\00c3";
}

.vi-correa:before {
  content: "\00c4";
}


.vi-heidenhain:before {
  content: "\00c5";
}

.vi-fanuc:before {
  content: "\00c6";
}

.vi-fidia:before {
  content: "\00c7";
}

.vi-siemens:before {
  content: "\00c8";
}

.vi-heidenhainlogo:before {
  content: "\00c9";
}

.vi-addilan:before {
  content: "\00ca";
}

.vi-addilanlogo:before {
  content: "\00cb";
}
