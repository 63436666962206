/* MATERIAL ICONS */
/*@import '~https://fonts.googleapis.com/icon?family=Roboto:100,300,400,500,700';*/
/*@import url('https://fonts.googleapis.com/css?family=Roboto');*/
/* @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700'); */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
  font-family: 'Roboto';
}

/* Para corregir el ancho del footer. Por culpa de tener el material design lite y el material2*/
/* Cuando se quite el material design lite, se podrá quitar esta ñapa*/
/*.cdk-overlay-container .mat-snack-bar-handset {*/
  /*width: auto;*/
/*}*/

.white-label .mat-form-field-label{
  color: #FFFFFF !important;
}

.white-label .mat-form-field-underline
{
  background-color: #FFFFFF;
  /* color: #FFFFFF !important; */
}


.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0 !important;
}
/*.mat-card-header-text{*/
  /*margin: 0 0px !important;*/
/*}*/

/*mat-form-field {*/
  /*width: 48%;*/
  /*padding: 1%;*/
/*}*/
/*@media only screen and (max-width: 500px) {*/
  /*mat-form-field {*/
    /*width: 100%;*/
  /*}*/
/*}*/

/*mat-card{*/
  /*padding:0px !important;*/
/*}*/
/*mat-card-content{*/
  /*margin: 24px;*/
/*}*/

.cdk-overlay-pane {
  width: auto !important;
}

.pointer {
  cursor: pointer;
}